import React from "react";

function FillLight({color="#ffffff", 
                    intensity=0.7,
                    position=[-3,5,5],
                    width=10,
                    height=10}) {
  return(
    <rectAreaLight
      width={width}
      height={height}
      intensity={intensity}
      color={color}
      position={position}
    />
  );
}

export default FillLight