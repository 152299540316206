import React from "react";

function BackLight({color="#ffffff", intensity=1.5}) {
  return(
    <rectAreaLight
      width={7}
      height={5}
      intensity={intensity}
      color={color}
      position={[1,4,-2]}
      rotation={[1.5, Math.PI/2.0 + .7, 0]}
      lookAt={[0,0,0]}
    />
  );
}

export default BackLight