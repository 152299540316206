/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Ahmed Emara (https://sketchfab.com/emara666)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/pedestal-fan-fbde233657574586aa5e8fe83b2f4675
title: Pedestal Fan
*/

import React, { useState, useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({isAnimated=true, castShadow=false, receiveShadow=false, ...props}) {
  const group = useRef();
  const [animationIsInitialized, setAnimationIsInitialized] = useState(false);
  const { nodes, materials, animations } = useGLTF('/pedestalFan.gltf');
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    const action = actions.Animation;
    if (isAnimated) {
      if (animationIsInitialized) {
        action.paused = false;
      } else{
        action.play(); // start animation for first time.
        setAnimationIsInitialized(() => true);
      }
    } else {
      action.paused = true;
    }
  }, [isAnimated, animationIsInitialized, actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Cylinder002_18" position={[0.03, 1.15, -0.03]} rotation={[0, 0.7, 0]} scale={[0.08, 0.26, 0.08]}>
                <group name="Cylinder_11" position={[0, 0.35, 0.69]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_30" geometry={nodes.Object_30.geometry} material={materials.Metal} />
                </group>
                <group name="Cylinder003_12" position={[-0.02, 0.35, 3.49]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_32" geometry={nodes.Object_32.geometry} material={materials.Light_Gray_Plastic} />
                </group>
                <group name="Cylinder004_13" position={[0, 0.35, 0.69]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_34" geometry={nodes.Object_34.geometry} material={materials.Light_Gray_Plastic} />
                </group>
                <group name="Cylinder005_14" position={[0, 0.35, 0.69]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_36" geometry={nodes.Object_36.geometry} material={materials.Light_Gray_Plastic} />
                </group>
                <group name="Cylinder006_15" position={[0.39, 0.64, -1.14]} scale={[9.46, 3.88, 9.46]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_38" geometry={nodes.Object_38.geometry} material={materials.Dark_Grey_Plastic} />
                </group>
                <group name="Cylinder007_16" position={[-0.01, 0.35, 1.23]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_40" geometry={nodes.Object_40.geometry} material={materials.Metal} />
                </group>
                <group name="Cylinder008_17" position={[-0.01, 0.35, 1.24]} scale={[3.11, 1, 3.11]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_42" geometry={nodes.Object_42.geometry} material={materials.Dark_Grey_Plastic} />
                </group>
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_26" geometry={nodes.Object_26.geometry} material={materials.White_Plastic} />
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_27" geometry={nodes.Object_27.geometry} material={materials.Black} />
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_28" geometry={nodes.Object_28.geometry} material={materials.Light_Gray_Plastic} />
              </group>
              <group name="Cylinder010_20" position={[0.09, 1.23, 0.04]}>
                <group name="Cylinder011_19">
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_47" geometry={nodes.Object_47.geometry} material={materials.Light_Gray_Plastic} />
                </group>
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_44" geometry={nodes.Object_44.geometry} material={materials['Glass_2.0']} />
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_45" geometry={nodes.Object_45.geometry} material={materials.Metal} />
              </group>
              <group name="Cube_8">
                <group name="Cube001_0">
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_6" geometry={nodes.Object_6.geometry} material={materials.White_Plastic} />
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_7" geometry={nodes.Object_7.geometry} material={materials.Light_Gray_Plastic} />
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_8" geometry={nodes.Object_8.geometry} material={materials.Metal} />
                </group>
                <group name="Cube002_1" position={[0.03, 0.49, 0.05]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_10" geometry={nodes.Object_10.geometry} material={materials.Dark_Grey_Plastic} />
                </group>
                <group name="Cube003_2" position={[0, 1.04, -0.04]} scale={0.07}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_12" geometry={nodes.Object_12.geometry} material={materials.White_Plastic} />
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_13" geometry={nodes.Object_13.geometry} material={materials.Black} />
                </group>
                <group name="Cube004_3" position={[0, 1.11, -0.02]} scale={0.07}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_15" geometry={nodes.Object_15.geometry} material={materials.White_Plastic} />
                </group>
                <group name="Cube005_4">
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_17" geometry={nodes.Object_17.geometry} material={materials.Screen_Plastic} />
                </group>
                <group name="Cube006_5">
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_19" geometry={nodes.Object_19.geometry} material={materials.Screen_Plastic} />
                </group>
                <group name="Cylinder001_6" position={[0, 1.14, -0.05]} scale={0.02}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_21" geometry={nodes.Object_21.geometry} material={materials.Metal} />
                </group>
                <group name="Cylinder009_7" position={[0, 0.59, -0.11]} scale={[0.5, 0.18, 0.5]}>
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_23" geometry={nodes.Object_23.geometry} material={materials.Light_Gray_Plastic} />
                  <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_24" geometry={nodes.Object_24.geometry} material={materials.Metal} />
                </group>
                <mesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_4" geometry={nodes.Object_4.geometry} material={materials.Light_Gray_Plastic} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/pedestalFan.gltf')
