import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import AxesHelper from "./components/AxesHelper";
import DragMouseToMoveMessage from "./components/DragMouseToMoveMessage";
import LoadingMessage from "./components/LoadingMessage";
import GroundPlane from "./components/GroundPlane";
import BackPlane from "./components/BackPlane";
import KeyLight from "./components/KeyLight";
import FillLight from "./components/FillLight";
import BackLight from "./components/BackLight";
import PedestalFan from "./components/PedestalFan";
import ElectricFan from "./components/ElectricFan";
import InfoModal from "./components/InfoModal";

import './App.css';

function App() {
  const deg2rad = degrees => degrees * (Math.PI / 180);
  const [dragMessageIsVisible, setDragMessageIsVisible] = useState(true);
  var isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
  const [isAnimated, setIsAnimated] = useState(isLocalHost ? false : true);
  let mouseDownX = undefined;
  let mouseDownY = undefined;

  return (
    <>
      <DragMouseToMoveMessage isVisible={dragMessageIsVisible} />
      <div id="buttons-area" className="no-print">
        <InfoModal open={false} />
      </div>
      <Canvas
        shadows
        camera={{
          position: [0, 2.0, 5], 
          zoom: 1.9
        }}
        onMouseDown={(e) => {
          mouseDownX = e.clientX;
          mouseDownY = e.clientY;
        }}
        onMouseMove={(e) => {
          mouseDownX = undefined;
          mouseDownY = undefined;
          if (e.buttons !== 0 /*any button down*/) {
            setDragMessageIsVisible((isVisible) => false);
          }
        }}
        onMouseUp={(e) => {
          if (e.clientX === mouseDownX && e.clientY === mouseDownY) {
            setIsAnimated(() => !isAnimated);
          }
        }}
        onTouchMove={() => {
          setDragMessageIsVisible(isVisible => false);
        }}
      >
        {/* <AxesHelper /> */}
        <OrbitControls 
          makeDefault
          maxPolarAngle={Math.PI/2.0}
          minPolarAngle={0}
          target={[0,1.4,0]}
        />
        <KeyLight />
        <FillLight />
        <BackLight />
        <Suspense fallback={<LoadingMessage position={[-2,2,0]} />}>
          <PedestalFan
            isAnimated={isAnimated}
            castShadow
            position={[-1,0,0]} 
            scale={[2,2,2]}
          />
        </Suspense>
        <Suspense fallback={<LoadingMessage position={[2,2,0]} />}>
          <ElectricFan
            isAnimated={isAnimated}
            castShadow
            receiveShadow
            position={[1,0,0]} 
            scale={[.035,.035,.035]}
            rotation={[0, deg2rad(20), 0]}
          />
        </Suspense>
        <GroundPlane />
        <BackPlane />
      </Canvas>
    </>
  );
}

export default App;
