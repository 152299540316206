import React from "react";

function BackPlane({color="#8c9bd2", receiveShadow=true}) {
  return(
    <mesh position={[0,5,-5]} receiveShadow={receiveShadow}>
      <planeBufferGeometry attach="geometry" args={[40,10]} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
}

export default BackPlane