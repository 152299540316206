import React from "react";
import { Button } from "@mui/material";
import InfoIcon from "../../icons/InfoIcon";
import "./ButtonsBase.css"

const InfoButton = ({onClick}) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      className="buttons-base"
      size="large"
      aria-label="Info"
    >
      <InfoIcon fontSize="large" />
    </Button>
)};

export default InfoButton;