import React from "react";

function GroundPlane({color="#b69567", receiveShadow=true}) {
  return(
    <mesh rotation={[-Math.PI/2.0,0,0]} position={[0,0,0]} receiveShadow={receiveShadow}>
      <planeBufferGeometry attach="geometry" args={[40,10]} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
}

export default GroundPlane