import React, { useState } from "react";
import { Dialog, AppBar, Toolbar, Typography, IconButton, Slide, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoButton from "./buttons/InfoButton";

import "./InfoModal.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="left" ref={ref} {...props}  timeout={0}
      easing={{
        enter: "none",
        exit: "none"
      }}
    />
  );
});

export default function InfoModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => setIsOpen(false),
      100);
  };

  const handleExited = () => {
    setIsClosing(false);
  }

  return (
    <>
      <InfoButton
        onClick={() => {setIsOpen(true);}}
      />
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        TransitionProps={{
          onExited: handleExited
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              Information
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="info-modal">
          <h3>Model Attribution</h3>
          <h4>Pedestal Fan</h4>
          <ul>
            <li>
              Author: Ahmed Emara (<a href="https://sketchfab.com/emara666" rel="noreferrer" target="_blank">https://sketchfab.com/emara666</a>)
            </li>
            <li>
              Licence: CC-BY-4.0 (<a href="http://creativecommons.org/licenses/by/4.0/" rel="noreferrer" target="_blank">http://creativecommons.org/licenses/by/4.0/</a>)
            </li>
            <li>
              Source: <a href="https://sketchfab.com/3d-models/pedestal-fan-fbde233657574586aa5e8fe83b2f4675" rel="noreferrer" target="_blank">https://sketchfab.com/3d-models/pedestal-fan-fbde233657574586aa5e8fe83b2f4675</a>
            </li>
            <li>
              Title: Pedestal Fan
            </li>
          </ul>
          <h4>Electric Fan</h4>
          <ul>
            <li>
              Author: Lillya (<a href="https://sketchfab.com/Lillya" rel="noreferrer" target="_blank">https://sketchfab.com/Lillya</a>)
            </li>
            <li>
              Licence: CC-BY-4.0 (<a href="http://creativecommons.org/licenses/by/4.0/" rel="noreferrer" target="_blank">http://creativecommons.org/licenses/by/4.0/</a>)
            </li>
            <li>
              Source: <a href="https://sketchfab.com/3d-models/electric-fan-c27f71e432b84353a245fb9b65dcdbf2" rel="noreferrer" target="_blank">https://sketchfab.com/3d-models/electric-fan-c27f71e432b84353a245fb9b65dcdbf2</a>
            </li>
            <li>
              Title: Electric Fan
            </li>
          </ul>
        </div>
      </Dialog>
      <Modal open={isClosing}>
        <></>
      </Modal>
    </>
  );
}