/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Lillya (https://sketchfab.com/Lillya)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/electric-fan-c27f71e432b84353a245fb9b65dcdbf2
title: Electric Fan
*/

import React, { useState, useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Model({ isAnimated=true, castShadow=false, receiveShadow=false, ...props }) {
  const group = useRef();
  const [animationIsInitialized, setAnimationIsInitialized] = useState(false);
  const { nodes, materials, animations } = useGLTF('/electricFan.gltf');
  const { actions } = useAnimations(animations, group)
  useEffect(() => {;
    const action = actions['Armature|Action'];
    if (isAnimated) {
      if (animationIsInitialized) {
        action.paused = false;
      } else{
        action.play(); // start animation for first time.
        setAnimationIsInitialized(() => true);
      }
    } else {
      action.paused = true;
    }
  }, [isAnimated, animationIsInitialized, actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="0a20d622ef3d4820a916482dca9888a2fbx" rotation={[Math.PI / 2, 0, 0]}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Armature" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <group name="Object_5">
                    <primitive object={nodes._rootJoint} />
                    <group name="Object_12" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                    <skinnedMesh castShadow={castShadow} receiveShadow={receiveShadow} name="Object_13" geometry={nodes.Object_13.geometry} material={materials.fan_material} skeleton={nodes.Object_13.skeleton} />
                  </group>
                </group>
                <group name="eletric_fan" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/electricFan.gltf')
