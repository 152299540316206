import React from "react";

function KeyLight({color="#ffffff", 
                    intensity=.9, 
                    position=[5,7,6], 
                    width=10, 
                    height=10}) {
  return(
    <
      spotLight
      penumbra={1}
      width={width}
      height={height}
      intensity={intensity}
      color={color}
      position={position}
      castShadow
    />
  );
}

export default KeyLight